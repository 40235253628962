import React, { useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';

import {
  Container, Title, Subtitle, Terms, Start, Back, InputWrapper, TermsTittle,
} from './styles';

import Input from '../../components/Fields/Input';
import { isValidCnpj } from '../../services/cnpjValidator';

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    height: '80%',
    bottom: 'auto',
    width: '450px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function Home() {
  const history = useHistory();
  const [validCnpj, setValidCnpj] = useState(false);
  const [cnpj, setCnpj] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showTerm, setShowTerm] = useState(false);

  const handlerInputChange = (value) => {
    if (isValidCnpj(value)) {
      setValidCnpj(true);
      setCnpj(value);
    } else {
      setValidCnpj(false);
    }
  };

  const handlerStartClick = () => {
    if (validCnpj && acceptedTerms) {
      history.push(`/boletos/${cnpj}`);
    }
  };

  const handlerCheckboxChange = (e) => {
    setAcceptedTerms(e.target.checked);
  };

  return (
    <Container>
      <Title>
        <FormattedMessage id="home.title" />
      </Title>

      <Subtitle>
        <FormattedMessage id="home.subtitle" />
      </Subtitle>

      <div>
        <InputWrapper>
          <Input
            id="cnpj"
            label="CNPJ"
            onChange={handlerInputChange}
            success={validCnpj}
          />
        </InputWrapper>

        <Terms>
          <input type="checkbox" onChange={handlerCheckboxChange} />
          <span>
            <FormattedMessage id="home.termsAgree" />
            {' '}
            {/* eslint-disable-next-line */}
            <a onClick={() => setShowTerm(true)} href="#">
              <FormattedMessage id="home.termsLink" />
            </a>
          </span>
        </Terms>
      </div>

      <Start success={validCnpj && acceptedTerms} onClick={handlerStartClick}>
        <FormattedMessage id="home.start" />
      </Start>

      <Back>
        <FaChevronLeft />
        <FormattedMessage id="home.back" />
      </Back>

      <Modal
        isOpen={showTerm}
        onRequestClose={() => setShowTerm(false)}
        style={modalStyle}
      >
        <TermsTittle>Termos de uso</TermsTittle>
        <FormattedMessage id="home.terms" />
      </Modal>
    </Container>
  );
}

export default Home;
