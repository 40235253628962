export const PUSHER_MESSAGE_STATUS = {
  ERROR_READ_CAPTCHA: 'ERROR_READ_CAPTCHA',
};

export const PUSHER_EVENTS_NAME = {
  MESSAGE_PROCESSED: 'MESSAGE_PROCESSED',
  MESSAGE_ERROR: 'MESSAGE_ERROR',
};

export const BILL_STATUSES = {
  LIQUIDADO: 'LIQUIDADO',
  DEVEDOR: 'DEVEDOR',
  'A VENCER': 'A VENCER',
};

export default {
  PUSHER_MESSAGE_STATUS,
  PUSHER_EVENTS_NAME,
  BILL_STATUSES,
};
