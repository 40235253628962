import styled from 'styled-components';

const info = {
  background: '#bee5eb',
  borderColor: '#286f7b',
};

const success = {
  background: '#d4edda',
  borderColor: '#98c5a3',
};

const warning = {
  background: '#FAFCDE',
  borderColor: '#F6FF71',
};

const error = {
  background: '#f8d7da',
  borderColor: '#e298a0',
};

const applyStyle = (props) => {
  if (props.alertStyle === 'warning') {
    return warning;
  }

  if (props.alertStyle === 'success') {
    return success;
  }

  if (props.alertStyle === 'error') {
    return error;
  }

  return info;
};

export default styled.div`
  color: #4D4D4D;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5em;
  margin-top: 25px;
  width: 100%;
  border: 1px solid;
  ${(props) => applyStyle(props)}
`;
