import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.85);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? 'auto' : 'none')};
  transition: all 0.3s;
`;

export const Container = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #ffffff;
  text-align: center;
  border-radius: 10px;
  padding: 20px 40px;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 400px) {
    width: 100%;
    height: 100%;
    padding: 5em 0;
    border-radius: 0;
    justify-content: center;

    > div {
      margin-bottom: 2em;
    }
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  font-weight: 700;
  font-size: 22px;
  cursor: pointer;
`;

export const Date = styled.div`
  div {
    font-weight: 700;
    font-size: 20px;
    text-transform: capitalize;
  }

  div:first-child {
    font-family: 'Baloo Bhaina 2', sans-serif;
    margin-bottom: 5px;
    font-size: 26px;
    color: #50bc49;
  }

  > span:last-child {
    font-size: 12px;
  }

  div span:first-child {
    font-size: 36px;
  }
`;

export const Numbers = styled.div`
  font-size: 20px;
  width: 50%;
  margin: auto;
  line-height: 1.5em;
  cursor: pointer;
  text-align: center;
  overflow-wrap: break-word;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  line-height: 40px;

  a {
    width: 50%;
    background: #50bc49;
    border: 0;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    font-weight: 700;
    padding: 0 15px;
    cursor: pointer;
    margin-bottom: 10px;
    height: 40px;
    display: block;
    align-self: center;

    :last-child {
      margin-right: 0;
    }
  }
`;
