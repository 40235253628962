const LOGS_TYPE = {
  ERROR: 'error',
  INFO: 'info',
};

const log = (type, msg, params) => {
  if (process.env.NODE_ENV === 'development') {
    if (!LOGS_TYPE[type]) {
      throw Error('Tipo de log não encontrado.');
    }

    if (msg) {
      if (window.console[LOGS_TYPE[type]]) {
        window.console[LOGS_TYPE[type]](msg, ...params);
      } else {
        window.console.log(`[${type}] ${msg}`, ...params);
      }
    }
  }
};

export const error = (msg, ...params) => {
  log('ERROR', msg, params);
};

export const info = (msg, ...params) => {
  log('INFO', msg, params);
};

export default {
  error,
  info,
};
