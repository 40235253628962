export const Types = {
  SET_BILLS: 'bills/SET_BILLS',
  SET_CNPJ: 'bills/SET_CNPJ',
  SET_LOADING: 'bills/SET_LOADING',
  SET_YEARS_OPTION: 'bills/SET_YEARS_OPTION',
  SET_BILLS_STATUS: 'bills/SET_BILLS_STATUS',
  SET_SEARCH_YEAR_OPTION: 'bills/SET_SEARCH_YEAR_OPTION',
  SET_SEARCH_STATUS_OPTION: 'bills/SET_SEARCH_STATUS_OPTION',

  ADD_ALERT: 'bills/ADD_ALERT',
  CLEAR_ALERT: 'bills/CLEAR_ALERT',
  ADD_INFO_ALERT: 'bills/ADD_INFO_ALERT',
  ADD_WARNING_ALERT: 'bills/ADD_WARNING_ALERT',
  ADD_ERROR_ALERT: 'bills/ADD_ERROR_ALERT',
  ADD_SUCCESS_ALERT: 'bills/ADD_SUCCESS_ALERT',
};

const INITIAL_STATE = {
  bills: [],
  cnpj: '',
  loading: true,
  yearsOption: [],
  billsStatus: [],
  searchOptions: { year: '', status: '' },
  alerts: {
    info: [], warning: [], error: [], success: [],
  },
};

export default function bills(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_BILLS:
      return {
        ...state,
        bills: action.payload.bills,
      };
    case Types.SET_CNPJ:
      return {
        ...state,
        cnpj: action.payload.cnpj,
      };
    case Types.SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case Types.SET_YEARS_OPTION:
      return {
        ...state,
        yearsOption: action.payload.years,
      };
    case Types.SET_BILLS_STATUS:
      return {
        ...state,
        billsStatus: action.payload.statuses,
      };
    case Types.SET_SEARCH_YEAR_OPTION:
      return {
        ...state,
        searchOptions: {
          year: action.payload.year,
          status: state.searchOptions.status,
        },
      };
    case Types.SET_SEARCH_STATUS_OPTION:
      return {
        ...state,
        searchOptions: {
          year: state.searchOptions.year,
          status: action.payload.status,
        },
      };
    case Types.ADD_ALERT:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          [action.payload.type]: [...state.alerts[action.payload.type], action.payload.msg],
        },
      };
    case Types.CLEAR_ALERT:
      return {
        ...state,
        alerts: INITIAL_STATE.alerts,
      };
    case Types.ADD_INFO_ALERT:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          info: [...state.alerts.info, action.payload.info],
        },
      };
    case Types.ADD_WARNING_ALERT:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          warning: [...state.alerts.warning, action.payload.warning],
        },
      };
    case Types.ADD_ERROR_ALERT:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          error: [...state.alerts.error, action.payload.error],
        },
      };
    case Types.ADD_SUCCESS_ALERT:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          success: [...state.alerts.success, action.payload.success],
        },
      };
    default:
      return state;
  }
}

export const Creators = {
  setBills: (_bills) => ({
    type: Types.SET_BILLS,
    payload: {
      bills: _bills,
    },
  }),
  setCNPJ: (cnpj) => ({
    type: Types.SET_CNPJ,
    payload: {
      cnpj,
    },
  }),
  setLoading: (loading) => ({
    type: Types.SET_LOADING,
    payload: {
      loading,
    },
  }),
  setYearsOption: (years) => ({
    type: Types.SET_YEARS_OPTION,
    payload: {
      years,
    },
  }),
  setBillsStatus: (statuses) => ({
    type: Types.SET_BILLS_STATUS,
    payload: {
      statuses,
    },
  }),
  setSearchYearOption: (year) => ({
    type: Types.SET_SEARCH_YEAR_OPTION,
    payload: {
      year,
    },
  }),
  setSearchStatusOption: (status) => ({
    type: Types.SET_SEARCH_STATUS_OPTION,
    payload: {
      status,
    },
  }),
  addAlert: (type, msg) => ({
    type: Types.ADD_ALERT,
    payload: {
      type,
      msg,
    },
  }),
  clearAlerts: () => ({
    type: Types.CLEAR_ALERT,
    payload: {},
  }),
  addInfoAlert: (info) => ({
    type: Types.ADD_INFO_ALERT,
    payload: {
      info,
    },
  }),
  addWarningAlert: (warning) => ({
    type: Types.ADD_WARNING_ALERT,
    payload: {
      warning,
    },
  }),
  addErrorAlert: (error) => ({
    type: Types.ADD_ERROR_ALERT,
    payload: {
      error,
    },
  }),
  addSuccessAlert: (success) => ({
    type: Types.ADD_SUCCESS_ALERT,
    payload: {
      success,
    },
  }),
};
