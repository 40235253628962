import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Container, SafeEnv } from './styles';

import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as Security } from '../../assets/icon-security.svg';

function NavBar() {
  return (
    <Container>
      <Logo />

      <SafeEnv>
        <div>
          <FormattedMessage id="generic.safeEnv" />
          <br />
          <FormattedMessage id="generic.safeData" />
        </div>

        <Security />
      </SafeEnv>

    </Container>
  );
}

export default NavBar;
