import React from 'react';

import Container from './styles';

function Alert({ children, alertStyle }) {
  return (
    <Container alertStyle={alertStyle}>
      {children}
    </Container>
  );
}

export default Alert;
