import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 40px;

  @media screen and (max-width: 500px) {
    padding: 60px 0;
  }
`;

export const Title = styled.h1`
  font-family: 'Baloo Bhaina 2', sans-serif;
  color: ${(props) => (props.theme.origin === 'bradesco' ? '#000000' : '#45B347')};
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  line-height: 1.5em;

  @media screen and (max-width: 500px) {
    font-size: 22px;
  }
`;

export const Subtitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  margin: 20px 0 60px;
  text-align: center;

  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
`;

export const Terms = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 60px;
  color: #666;

  input {
    margin-right: 20px;
    height: 24px;
    width: 24px;
  }

  a {
    color: #666;
    text-decoration: underline;
  }
`;

export const Start = styled.button`
  background: ${(props) => {
    if (props.success) {
      return props.theme.origin === 'bradesco' ? '#dc3545' : '#50bc49';
    }
    return '#ccc';
  }};
  border: 0;
  border-radius: 5px;
  text-align: center;
  width: 220px;
  height: 58px;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const Back = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-weight: 300;
  cursor: pointer;

  svg {
    margin-right: 10px;
    height: 20px;
  }
`;

export const InputWrapper = styled.div`
  width: 300px;

  @media screen and (max-width: 400px) {
    width: 220px;
  }
`;

export const TermsTittle = styled.h2`
  text-align: center;
  margin-bottom: 10px;
`;
