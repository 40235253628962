import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Container, Date, Due } from './styles';

import { BILL_STATUSES } from '../../constants';

function CardBoleto({ boleto, onClick }) {
  const intl = useIntl();
  const isPaid = boleto.status && (boleto.status.toUpperCase()).indexOf(BILL_STATUSES.LIQUIDADO) !== -1;

  const handlerClickCard = () => {
    if (!isPaid) {
      onClick(boleto);
    }
  };

  return (
    <Container red={!isPaid} onClick={handlerClickCard}>
      <Date>
        <span>{boleto.shortMonth}</span>
        <span>{boleto.shortYear}</span>
      </Date>

      {isPaid ? (
        <span><FormattedMessage id="cardBoleto.paid" /></span>
      ) : (
        <Due>
          <div>
            <span>R$</span>
            <span>
              {boleto.totalInt}
              ,
            </span>
            <span>{boleto.totalDec}</span>
          </div>

          <button
            type="button"
            label={intl.formatMessage({ id: 'cardBoleto.due' })}
          >
            <FormattedMessage id="cardBoleto.due" />
          </button>
        </Due>
      )}
    </Container>
  );
}

export default CardBoleto;
