import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 40px;

  @media screen and (max-width: 500px) {
    padding: 60px 0;
  }
`;

export const Title = styled.h1`
  font-family: 'Baloo Bhaina 2', sans-serif;
  color: ${(props) => (props.theme.origin === 'bradesco' ? '#000000' : '#45B347')};
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  line-height: 1.5em;

  @media screen and (max-width: 500px) {
    font-size: 22px;
  }
`;

export const Subtitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  margin: 20px 0 60px;
  text-align: center;

  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;

  select {
    width: 210px;
    height: 55px;
    padding: 20px 10px 10px;

    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position:
      calc(100% - 20px) calc(1.5em + 2px),
      calc(100% - 15px) calc(1.5em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      0px;
    background-repeat: no-repeat;
  }

  div + div {
    margin-left: 30px;
  }

  @media screen and (max-width: 550px) {
    flex-wrap: wrap;

    div + div {
      margin: 10px 0 0;
    }
  }
`;

export const AlertsWrapper = styled.div`
  width: 100%;
`;

export const Loading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;

  span {
    font-size: 22px;
  }

  svg {
    font-size: 33px;
    margin-bottom: 10px;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes spin {
      from {
        transform: rotate(0deg);
      } to {
        transform: rotate(360deg);
      }
    }
  }
`;

export const BoletosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 60px;
`;

export const Empty = styled.div`
  font-weight: 700;
  font-size: 20px;
`;
