import styled from 'styled-components';

const greenContainer = {
  border: '1px solid #50bc49',
  color: '#50bc49',
};

const redContainer = {
  border: '1px solid #cc092f',
  color: '#cc092f',
  cursor: 'pointer',
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  height: 150px;
  padding: 15px;
  margin: 0 20px 40px;
  box-shadow: 0px 0px 1px 0px;

  ${(props) => (props.red ? redContainer : greenContainer)}

  > span {
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 30px;
  }
`;

export const Date = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    text-transform: capitalize;
    font-size: 18px;
  }

  span + span {
    font-weight: 700;
  }
`;

export const Due = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;

  button, span {
    font-weight: 700;
  }

  button {
    height: 35px;
    border: 0;
    background: #cc092f;
    color: #fff;
    padding: 0 10px;
    text-transform: capitalize;
    border-radius: 2px;
    cursor: pointer;
  }

  div {
    display: flex;
    margin-bottom: 5px;

    span:first-child {
      margin-right: 3px;
    }

    span:nth-child(2) {
      font-size: 22px;
    }
  }
`;
