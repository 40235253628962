import React from 'react';
import PropTypes from 'prop-types';
import { FaCheck } from 'react-icons/fa';
import InputMask from 'react-input-mask';

import Container from './styles';

function Input({
  id, label, onChange, success,
}) {
  const handlerOnChange = (e) => {
    onChange(e.target.value.replace(/\D/g, ''));
  };

  return (
    <Container success={success}>
      {/* eslint-disable-next-line */}
      <label htmlFor={id}>{label}</label>
      <InputMask mask="99.999.999/9999-99" type="text" id={id} onChange={handlerOnChange} />
      <span><FaCheck /></span>
    </Container>
  );
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
};

export default Input;
