import Pusher from 'pusher-js';

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  forceTLS: process.env.REACT_APP_PUSHER_APP_FORCE_TLS,
});

export const listenChannel = (sessionId) => pusher.subscribe(sessionId);

export const unlistenChannel = (sessionId) => pusher.unsubscribe(sessionId);

export const disconnect = () => pusher.disconnect();

export default pusher;
