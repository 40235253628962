import React, { useRef, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaTimes } from 'react-icons/fa';

import {
  Modal, Container, Date, Numbers, Buttons, Close,
} from './styles';

function BoletoDetails({ data, show, clearActiveBill }) {
  const intl = useIntl();
  const toolTipRef = useRef(null);
  const modalContainerRef = useRef(null);

  const clickedOutside = useCallback((event) => {
    const isClickInsideElement = modalContainerRef.current.contains(event.target);
    if (!isClickInsideElement) {
      clearActiveBill();
      window.removeEventListener('click', clickedOutside);
    }
  }, [clearActiveBill]);

  const handlerCloseModal = () => {
    clearActiveBill();
    window.removeEventListener('click', clickedOutside);
  };

  useEffect(() => {
    if (show) {
      window.addEventListener('click', clickedOutside);
    }
  }, [show, clickedOutside]);

  return (
    <Modal show={show}>
      <Container ref={modalContainerRef} show={show}>
        <Close onClick={handlerCloseModal}>
          <FaTimes />
        </Close>
        <Date>
          <div>
            {data.month}
            {' '}
            /
            {' '}
            {data.shortYear}
          </div>
          <div>
            <span>
              {data.totalInt}
              ,
            </span>
            <span>{data.totalDec}</span>
          </div>
          <span>{data.due}</span>
        </Date>

        <CopyToClipboard
          text={data.barCode}
          onCopy={() => ReactTooltip.show(toolTipRef.current)}
        >
          <Numbers>
            <span
              ref={toolTipRef}
              data-tip="Copiado!"
              data-effect="solid"
              data-event="none"
              data-delay-hide="1000"
            >
              {data.barCode}
            </span>
            <ReactTooltip globalEventOff="click" />
          </Numbers>
        </CopyToClipboard>

        <Buttons>
          <a
            label={intl.formatMessage({ id: 'boletoDetails.open' })}
            href={data.file ? `data:image/pdf;base64,${data.file}` : null}
            download={data.file ? `${data.due}.pdf` : null}
          >
            <FormattedMessage id="boletoDetails.open" />
          </a>

          <a
            href="mailto:"
            label={intl.formatMessage({ id: 'boletoDetails.sendEmail' })}
          >
            <FormattedMessage id="boletoDetails.sendEmail" />
          </a>

          <a
            href="https://wa.me/"
            target="_blank"
            rel="noopener noreferrer"
            label={intl.formatMessage({ id: 'boletoDetails.sendWhats' })}
          >
            <FormattedMessage id="boletoDetails.sendWhats" />
          </a>
        </Buttons>
      </Container>
    </Modal>
  );
}

export default BoletoDetails;
