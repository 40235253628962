import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import ReactGA from 'react-ga';
import {
  get,
} from 'lodash';

import { ThemeProvider } from 'styled-components';

import './global.css';

import Routes from './routes';
import messages from './i18n';

import NavBar from './components/NavBar';
import Footer from './components/Footer';
import store from './store';

const url = window.location.href;

let defaultTheme = get(/(?:(?:[\w.]+)?boleto\.)?(?<origem>\b\w+)?\.dicasmei\.com\.br/gi.exec(url),
  'groups.origem', 'dicasmei');

defaultTheme = defaultTheme === 'boletodas' ? 'dicasmei' : defaultTheme;

if (defaultTheme === 'bradesco') {
  ReactGA.initialize('UA-112487562-5', {
    gaOptions: {
      cookieFlags: 'SameSite=None;Secure',
    },
  });
} else {
  ReactGA.initialize('UA-112487562-16');
}

function App() {
  const [theme, setTheme] = useState();
  const location = useLocation();

  useEffect(() => {
    setTheme(defaultTheme);
    ReactGA.pageview(window.location.pathname + window.location.hash);
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={{
      origin: theme,
    }}
    >
      <Provider store={store}>
        <IntlProvider locale="pt-BR" messages={messages['pt-BR']}>
          <NavBar />

          <div id="app">
            <main>
              <Routes />
            </main>
          </div>

          {location.pathname === '/' ? <Footer /> : ''}
        </IntlProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
