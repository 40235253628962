import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;

  > svg {
    width: 180px;
  }

  svg {
    height: 60px;
  }

  @media screen and (max-width: 470px) {
    > svg {
      height: 60px !important;
    }

    svg {
      height: 40px;
    }
  }
`;

export const SafeEnv = styled.div`
  display: flex;
  text-align: right;
  align-items: center;

  div {
    margin-right: 20px;
    font-weight: 700;
  }

  @media screen and (max-width: 470px) {
    div {
      font-size: 13px;
    }
  }
`;
