import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FaChevronUp } from 'react-icons/fa';

import {
  Container, SecurityWrapper, RightsWrapper, RightsButton, Text,
} from './styles';

import { ReactComponent as Security } from '../../assets/icon-security.svg';

function Footer() {
  return (
    <Container>
      <SecurityWrapper>
        <Security />

        <Text>
          <FormattedMessage id="generic.safeEnv" />
          <br />
          <FormattedMessage id="generic.safeData" />
        </Text>
      </SecurityWrapper>

      <RightsWrapper>
        <Text>
          <FormattedMessage id="footer.rightsReserved" />
        </Text>

        <RightsButton>
          <FaChevronUp />
        </RightsButton>
      </RightsWrapper>

    </Container>
  );
}

export default Footer;
