import styled from 'styled-components';

export default styled.div`
  border: solid 1px ${(props) => (props.success ? '#50bc49' : '#ccc')};
  box-sizing: border-box;
  margin-bottom: 8px;
  padding: 0 8px;
  position: relative;
  width: 100%;

  input, select {
    border: none;
    font-size: 16px;
    outline: 0;
    padding: 22px 3px 10px;
    width: 100%;
  }

  label {
    font-size: 16px;
    position: absolute;
    color: #666;
    top: 3px;
    left: 6px;
    transform: scale(.75);
  }

  span {
    color: #50bc49;
    position: absolute;
    top: 25%;
    right: 10px;
    font-size: 26px;
    display: ${(props) => (props.success ? 'block' : 'none')};
  }
`;
