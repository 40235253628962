import moment from 'moment';

import store from '../store';
import { Creators as BillsActions } from '../store/ducks/bills';

import api from './api';
import { listenChannel, unlistenChannel, disconnect as pusherDisconnect } from './pusher';
import { PUSHER_EVENTS_NAME, PUSHER_MESSAGE_STATUS } from '../constants';
import * as Logger from './logger';

const handlerPusherError = (response = {}, errorCallback = () => {}) => {
  Logger.error('handlerPusherError', response.sessionId);

  if (response && response.sessionId) {
    unlistenChannel(response.sessionId);
  }

  if (response && response.message) {
    if (response.message.status === PUSHER_MESSAGE_STATUS.ERROR_READ_CAPTCHA) {
      errorCallback();

      Logger.error('ERROR_READ_CAPTCHA');
      return;
    }

    if (response.message.descricao) {
      store.dispatch(BillsActions.setLoading(false));
      // Issue #11
      if (response.message.descricao.indexOf('Antes de prosseguir') > -1) {
        const dueYears = response.message.descricao.match(/\d+/g) || [];
        // eslint-disable-next-line
        store.dispatch(BillsActions.addInfoAlert(`Você possui declaração de DASN em atraso do(s) ano(s): ${dueYears.join(', ')}.<br />Para regularizar, <a href="https://www.dicasmei.com.br/servico/declaracao-de-imposto-de-renda-do-mei-dasn" target="_blank">clique aqui</a>`));
      } else {
        store.dispatch(BillsActions.addInfoAlert(response.message.descricao));
      }
    }
  }
};

const getBills = async (sessionId) => {
  Logger.info('getBills');
  const { data } = await api.get(`/api/boleto-das/consultar/${sessionId}`);

  if (data && data.response) {
    (data.response.mensagens || []).forEach(({ type, value }) => {
      store.dispatch(BillsActions.addAlert(type, value));
    });

    const billsToLoad = (data.response.apuracoes || []).map((bill) => {
      const due = moment(bill.dataVencimento, 'YYYY-MM-DD');
      const period = moment(bill.periodo, 'MMMM/YYYY');
      const [totalInt, totalDec = '00'] = bill.total.toString().replace('.', ',').split(',');

      return {
        due: due.format('DD/MM/YYYY'),
        period: period.format('MMMM/YY'),
        month: period.format('MMMM'),
        shortMonth: period.format('MMM'),
        monthNumber: +period.format('M'),
        year: period.format('YYYY'),
        shortYear: period.format('YY'),
        totalInt,
        totalDec,
        status: bill.situacao,
        barCode: bill.boleto && bill.boleto.barCode,
        file: bill.boleto && bill.boleto.fileBase64,
      };
    });

    store.dispatch(BillsActions.setBills(billsToLoad));
    store.dispatch(BillsActions.setLoading(false));
  }
};

export const getBillsSession = async () => {
  store.dispatch(BillsActions.setLoading(true));

  const state = store.getState();
  const { data } = await api.post('api/boleto-das/consultar', {
    cnpj: state.bills.cnpj,
    ano: state.bills.searchOptions.year,
    meses: null,
    boleto: true,
  });

  const channel = listenChannel(data.sessionId);
  Logger.info('getBillsSession', data.sessionId);

  channel.bind(PUSHER_EVENTS_NAME.MESSAGE_PROCESSED, () => {
    unlistenChannel(data.sessionId);
    getBills(data.sessionId);

    Logger.info('getBillsSession MESSAGE_PROCESSED', data.sessionId);
  });

  channel.bind(PUSHER_EVENTS_NAME.MESSAGE_ERROR, (response) => {
    handlerPusherError(response, getBillsSession);
  });

  getBills(data.sessionId);
};

const getBillsOptions = async (sessionId) => {
  Logger.info('getBillsOptions');
  const { data } = await api.get(`/api/boleto-das/filtro/${sessionId}`);

  if (data && data.response) {
    store.dispatch(BillsActions.setYearsOption(data.response.anoCalendarios || []));
    store.dispatch(BillsActions.setBillsStatus(Object.values(data.response.situacoes) || {}));

    getBillsSession();
  }
};

export const getBillOptionsSession = async () => {
  store.dispatch(BillsActions.setLoading(true));

  const state = store.getState();
  const { data } = await api.post('/api/boleto-das/filtro', { cnpj: state.bills.cnpj });

  const channel = listenChannel(data.sessionId);
  Logger.info('getBillOptionsSession', data.sessionId);

  channel.bind(PUSHER_EVENTS_NAME.MESSAGE_PROCESSED, () => {
    unlistenChannel(data.sessionId);
    getBillsOptions(data.sessionId);

    Logger.info('getBillOptionsSession MESSAGE_PROCESSED', data.sessionId);
  });

  channel.bind(PUSHER_EVENTS_NAME.MESSAGE_ERROR, (response) => {
    handlerPusherError(response, getBillOptionsSession);
  });

  getBillsOptions(data.sessionId);
};

export const disconnect = () => pusherDisconnect();

export default {
  getBillOptionsSession,
  getBillsSession,
  disconnect,
};
