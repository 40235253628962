export default {
  'generic.safeEnv': 'Ambiente seguro',
  'generic.safeData': 'Seus dados protegidos',

  'footer.rightsReserved': 'Todos os direitos reservados a DicasMEI',

  'home.title': 'Veja seus boletos DAS na hora com a DicasMEI!',
  'home.subtitle': 'Preencha as informações abaixo e veja todo o histórico de boletos.',
  'home.termsAgree': 'Li e aceito os',
  'home.termsLink': 'termos de uso',
  'home.start': 'Iniciar agora',
  'home.back': 'Voltar para home',

  'boletos.title': 'Meus Boletos DAS',
  'boletos.subtitle': 'Veja a relação completa de seus boletos DAS.',

  'cardBoleto.paid': 'Pago',
  'cardBoleto.due': 'Em aberto',

  'boletoDetails.open': 'Abrir',
  'boletoDetails.sendEmail': 'Enviar por Email',
  'boletoDetails.sendWhats': 'Enviar por Whatsapp',

  'home.terms': 'INSTRUMENTO PARTICULAR DE ACEITE DE LICENÇA DE USO DOS SERVIÇOS E SOLUÇÕES DA PLATAFORMA DICAS '
    + 'MEI Pelo presente instrumento particular e na melhor forma de direito, as partes abaixo: de um lado, - DICAS '
    + 'MEI ASSESSORIA EMPRESARIAL, inscrita no CNPJ sob o n.º 29.162.060/0001-92, com seus atos constitutivos '
    + 'devidamente registrados na Junta Comercial do Estado de São Paulo sob o NIRE nº 3523078006, em 28/11/2017, '
    + 'com sede social na Cidade de Bauru, Estado de São Paulo, na Rua Jorge Nasralla, nº 1-38, Vila Guedes de '
    + 'Azevedo, CEP 17012-330, doravante designada simplesmente DICAS MEI; e de outro lado, - Usuário, como a '
    + 'pessoa jurídica ou física devidamente cadastrada no banco de dados eletrônicos da Dicas MEI '
    + '(www.dicasmei.com.br) que de maneira inequívoca aderiu aos termos do presente Termo de Aceite, '
    + 'declarando-se ciente de todas as condições previstas neste instrumento, doravante designado simplesmente '
    + 'USUÁRIO, conforme descritas abaixo: CONSIDERANDO que: a) DICAS MEI desenvolveu e é titular de uma plataforma '
    + 'de serviços e soluções composta por base de dados, programas, aplicativos e seus futuros aperfeiçoamentos, '
    + 'melhorias e atualizações, o qual possui diversas funcionalidades que permitem operar de maneira diferenciada '
    + 'no setor de negócios do Microempreendedor Individual (MEI), Microempresas e Profissionais Liberais (doravante '
    + 'simplesmente designado PLATAFORMA); b) DICAS MEI possui equipamentos, instalações e pessoal adequados e '
    + 'capacitados para manter e operar a PLATAFORMA, conforme condições disponibilizadas aos usuários; c) o '
    + 'USUÁRIO, maior de 18 anos, menor emancipado ou em posse da devida autorização legal, tendo interesse em '
    + 'contratar os serviços prestados pela DICAS MEI, realizou seu cadastrado no banco de dados de DICAS MEI '
    + 'através do site www.dicasmei.com.br ou por meio do aplicativo; d) o USUÁRIO ao realizar seu cadastro aderiu, '
    + 'sem qualquer ressalva, ao presente Termo de Aceite, assim como aceitou todos e quaisquer instrumentos '
    + 'eletrônicos localizados no sitio virtual e aplicativo de DICAS MEI; e) o USUÁRIO desde já autoriza (i) '
    + 'a realização, em seu nome, de buscas diversas a serem realizadas por DICAS MEI em bases de dados públicas,'
    + ' cadastros públicos e portais governamentais e (ii) o armazenamento no banco de dados de DICAS MEI de '
    + 'quaisquer informações em nome do USUÁRIO; e e) o aceite do presente termo se deu de maneira eletrônica / '
    + 'online. Têm, entre si, certo e ajustado, celebrar o presente Instrumento Particular de Aceite de Licença '
    + 'de Uso dos Serviços e Soluções da Plataforma Dicas MEI, que se regerá pelas seguintes cláusulas e condições, '
    + 'que reciprocamente se outorgam e aceitam, a saber: I. OBJETO I.1. Constitui objeto deste Termo: a) a outorga, '
    + 'por DICAS MEI ao USUÁRIO, da licença de uso da PLATAFORMA, por prazo indeterminado, em caráter exclusivo, '
    + 'somente para uso individual do assinante ou pessoa por ele indicada; b) a prestação, por DICAS MEI ao USUÁRIO, '
    + 'dos serviços e oferta de soluções para que o USUÁRIO, ou pessoa por ele indicada utilizem plenamente a '
    + 'PLATAFORMA para o desenvolvimento de suas atividades; c) o recebimento, pelo USUÁRIO e através da DICAS MEI, '
    + 'de informações contábeis concernentes a sua Empresa como Microempreendedor Individual seja através de e-mail,'
    + ' sítio eletrônico, aplicativo ou outros meios eletrônicos; d) a prestação de informações adicionais, pelo '
    + 'USUÁRIO à DICAS MEI, sobre os seus negócios visando o cumprimento da Lei nº 9.613/1998, a qual dispõe sobre '
    + 'os crimes de "lavagem" ou ocultação de bens, direitos e valores. I.2. Sem prejuízo das demais disposições '
    + 'aplicáveis deste Termo, fica estabelecido que: a) é expressamente vedado ao USUÁRIO, direta ou indiretamente,'
    + ' licenciar e prestar serviços da PLATAFORMA a quaisquer terceiros para uso no exercício de suas atividades; e b)'
    + ' os direitos atribuídos ao USUÁRIO, em razão da licença, ora outorgada, são limitados àqueles expressamente '
    + 'estabelecidos neste Termo, sendo que DICAS MEI é e permanecerá a exclusiva titular dos direitos de propriedade '
    + 'intelectual e/ou autorais sobre a PLATAFORMA, não se caracterizando, por meio deste Termo, qualquer operação de'
    + ' cessão, transferência, venda ou alienação para ao USUÁRIO de qualquer de tais direitos. I.3. O USUÁRIO se '
    + 'compromete e obriga a operar a PLATAFORMA em seus próprios equipamentos e instalações, e aceitando, quando '
    + 'necessário, que a DICAS MEI efetue regularmente quaisquer atualizações, aperfeiçoamentos e melhorias que venham'
    + ' a ser desenvolvidas. II. DIREITOS E OBRIGAÇÕES BÁSICAS II.1. DICAS MEI outorga ao USUÁRIO, por prazo '
    + 'indeterminado, o direito exclusivo e intransferível de usar os serviços e soluções da PLATAFORMA somente'
    + ' para suas próprias operações, bem como para as operações de seu pessoal, exclusivamente no território'
    + ' brasileiro, de acordo com as disposições da documentação referente a PLATAFORMA. II.2. O USUÁRIO é e'
    + ' permanecerá, em qualquer caso, o único e integral responsável pelo cumprimento das disposições deste Termo,'
    + ' respondendo, perante a DICAS MEI na hipótese de descumprimento das disposições aqui estabelecidas. II.3. '
    + 'Fica vedado ao USUÁRIO: traduzir, decompilar ou praticar engenharia reversa da PLATAFORMA, ou permitir que '
    + 'terceiros o façam; alterar ou remover as identificações pertinentes a direitos autorais e de propriedade '
    + 'intelectual, marcas, logotipos, nomes comerciais e quaisquer outros avisos de propriedade intelectual da '
    + 'DICAS MEI que apareçam ou estejam incluídos na PLATAFORMA; e usar qualquer cópia de segurança da PLATAFORMA '
    + 'para qualquer finalidade. II. GARANTIAS III.1. A DICAS MEI declara e garante que a PLATAFORMA, sem qualquer'
    + ' modificação, cumpre substancialmente as especificações constantes no siítio eletrônico “www.dicasmei.com.br”,'
    + ' quando usado dentro dos limites neles definidos. III.2. A DICAS MEI fornecerá ao USUÁRIO, a todo tempo durante'
    + ' a duração deste, todas as condições de usabilidade necessárias para a adequada utilização das funcionalidades'
    + ' da PLATAFORMA. IV. CONDIÇÕES FINANCEIRAS IV.1. A compensação devida pelo USUÁRIO à DICAS MEI pela licença de'
    + ' uso da PLATAFORMA, objeto deste, é aquela que será estabelecida de mútuo e comum acordo entre as Partes em '
    + 'documento apartado deste instrumento, e que será paga pelo USUÁRIO à DICAS MEI nas condições ali previstas. '
    + 'V. PROPRIEDADE INTELECTUAL V.1. A PLATAFORMA é de exclusiva titularidade da DICAS MEI, que detém todos os '
    + 'direitos de propriedade intelectual e/ou autorais sobre a PALTAFORMA. Todos os resultados, trabalhos, métodos,'
    + ' ferramentas e conhecimento realizados e/ou desenvolvidos e/ou empregados na utilização da PLATAFORMA pelo'
    + ' USUÁRIO e na execução deste são e serão de exclusiva titularidade da DICAS MEI, cabendo a esta todos os '
    + 'respectivos direitos de propriedade intelectual e/ou autorais e correspondentes prerrogativas. Como '
    + 'consequência, o USUÁRIO não terá qualquer direito sobre a PLATAFORMA e respectivos aperfeiçoamentos, '
    + 'atualizações e melhorias, que não o de usá-los de acordo com as cláusulas e condições deste. V.2. O '
    + 'USUÁRIO deverá, prontamente, notificar a DICAS MEI caso venha a ter ciência do uso não autorizado da '
    + 'PLATAFORMA por qualquer pessoa ou entidade, prestando para a DICAS MEI toda a assistência que esta '
    + 'venha a razoavelmente requerer, caso decida adotar quaisquer medidas judiciais ou extrajudiciais contra '
    + 'tal pessoal ou entidade. V.3. DICAS MEI declara que a PLATAFORMA e seu uso não infringem direitos de '
    + 'propriedade intelectual e/ou autorais de terceiros, reconhecidos sob as leis da República Federativa do'
    + ' Brasil e/ou de qualquer outro país. V.4. Desde que cumpridas as condições estabelecidas na Cláusula '
    + 'V.4 acima, DICAS MEI ressarcirá ao USUÁRIO o valor de qualquer indenização a que esta venha a ser condenada'
    + ' a pagar, em decisão judicial final transitada em julgado, bem como custas e despesas processuais incorridas'
    + ' pelo USUÁRIO e honorários advocatícios razoáveis, em ação proposta por terceiros fundada em direitos de'
    + ' propriedade intelectual e/ou autorais sobre a PLATAFORMA. V.4.1. Na hipótese de o USUÁRIO receber qualquer'
    + ' notificação expedida por terceiros, judicial ou extrajudicial, ou ser citada para ação judicial, sob a '
    + 'alegação de que o uso da PLATAFORMA, nas condições aqui estabelecidas, viola direitos de propriedade '
    + 'intelectual e/ou autorais, ou caso DICAS MEI entenda que quaisquer de tais atos podem vir a ocorrer, DICAS'
    + ' MEI deverá substituir o componente pertinente da PLATAFORMA por outro com funcionalidade similar e que '
    + 'não seja violador de direitos de propriedade intelectual e/ou autorais de terceiros. V.5. DICAS MEI não '
    + 'assume qualquer responsabilidade por quaisquer danos, ônus, despesas, custos e prejuízos em que o USUÁRIO '
    + 'venha a incorrer, como consequência de violação de direitos de propriedade intelectual e/ou autorais de '
    + 'terceiros em razão do uso da PLATAFORMA em desacordo com este. VI. CONFIDENCIALIDADE VI.1. Cada uma das '
    + 'Partes deverá considerar e manter, sob estrito sigilo e confidencialidade, todas e quaisquer informações, '
    + 'dados e conhecimentos disponibilizados pela outra Parte durante a execução deste, independentemente de sua '
    + 'natureza e da forma como disponibilizadas (“Informações Confidenciais”), sendo expressamente vedado à Parte '
    + 'receptora divulgar e/ou utilizar e/ou explorar, por qualquer forma ou meio, total ou parcialmente, direta ou '
    + 'indiretamente, as Informações Confidenciais da outra Parte. VI.2. As Informações Confidenciais poderão ser '
    + 'divulgadas pela Parte receptora para seus empregados, prepostos e contratados que a elas necessitem ter '
    + 'acesso para a execução de atividades vinculadas a este e que subscrevam um compromisso de confidencialidade '
    + 'com, no mínimo, o mesmo nível de proteção aqui estabelecido. VI.3. O USUÁRIO reconhece que a estrutura e '
    + 'organização da PLATAFORMA (em particular, mas não limitado a, seus componentes informáticos, aplicações, '
    + 'informações e especificações técnicas, funções, métodos, tecnologia e outras informações similares) são '
    + 'consideradas Informações Confidenciais de DICAS MEI, sendo e a todo tempo devendo permanecer de titularidade'
    + ' única e exclusiva de DICAS MEI, salvo prévia e expressa autorização por escrito de DICAS MEI em sentido '
    + 'diverso. VI.4. A DICAS MEI reconhece que todas e quaisquer informações, dados, projetos e conhecimentos '
    + 'disponibilizados pelo USUÁRIO, que sejam compilados, registrados, salvos e/ou que de qualquer outra maneira'
    + ' venham a ser inseridos na PLATAFROMA e/ou em sua base de dados eletrônica, são considerados Informações '
    + 'Confidenciais do USUÁRIO, sendo e a todo tempo devendo permanecer de titularidade única e exclusiva do USUÁRIO,'
    + ' salvo prévia e expressa autorização por escrito do USUÁRIO em sentido diverso. VI.5. Não serão consideradas '
    + 'Informações Confidenciais as informações, dados, conhecimentos, documentos e ferramentas que: a) já eram de '
    + 'conhecimento público ao tempo de sua divulgação ou venham a se tornar públicas sem violação das obrigações de '
    + 'confidencialidade aqui assumidas; b) tenham sido desenvolvidas pela Parte internamente, sem violação das '
    + 'obrigações de confidencialidade aqui assumidas ou que tenham sido obtidas de terceiros, não vinculados por '
    + 'qualquer obrigação de confidencialidade; e c) tenham sido recebidas pela Parte sem aviso de confidencialidade '
    + 'e que, por sua natureza, a Parte recebedora não devesse considerar como tal. VI.5.1. Não será considerada '
    + 'violação das obrigações de confidencialidade aqui estabelecidas o fornecimento de informações em cumprimento '
    + 'de ordem judicial ou administrativa válida recebida pela Parte, que deverá, de imediato comunicar o recebimento '
    + 'da ordem para a outra Parte. VI.6. As obrigações de confidencialidade assumidas neste permanecerão válidas e '
    + 'eficazes durante a respectiva vigência e pelo prazo de 5 anos a contar de seu término ou rescisão a qualquer '
    + 'título, sendo que, em relação às informações pertinentes a PLATAFORMA, as obrigações de confidencialidade '
    + 'permanecerão válidas durante a vigência dos respectivos direitos de propriedade intelectual e/ou autorais. '
    + 'VII. PRAZO DE VIGÊNCIA – RESCISÃO E SEUS EFEITOS. VII.1. Este Termo entra em vigor na data de sua assinatura '
    + 'digital / online e vigorará por prazo indeterminado. VII.2. Este Contrato poderá ser rescindido: a) '
    + 'unilateralmente por DICAS MEI, hipótese em que deverá comunicar o USUÁRIO por escrito a respeito, mediante '
    + 'pré-aviso mínimo de 30 (trinta) dias da data em que se tornará eficaz a rescisão; b) unilateralmente pelo '
    + 'USUÁRIO, hipótese em que deverá comunicar à DICAS MEI por escrito a respeito, mediante pré-aviso mínimo de 30 '
    + '(trinta) dias da data em que se tornará eficaz a rescisão; c) ocorrendo o descumprimento por qualquer das Partes'
    + ' de obrigação que lhe compita conforme disposto neste ou de qualquer outra disposição validamente estabelecida '
    + 'por escrito entre as Partes, não remediado tal descumprimento em prazo...',
};
