import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { Creators as BillsActions } from '../../store/ducks/bills';
import * as BillsService from '../../services/bills';

import {
  Container, Title, Subtitle, Form, BoletosWrapper, Loading, Empty,
} from './styles';

import CardBoleto from '../../components/CardBoleto';
import BoletoDetails from '../../components/BoletoDetails';
import Select from '../../components/Fields/Select';
import Alerts from './Alerts';

function Boletos() {
  const { cnpj } = useParams();
  const dispatch = useDispatch();
  const [activeBill, setActiveBill] = useState({});
  const [filteredBills, setFilteredBills] = useState([]);

  const bills = useSelector((state) => state.bills.bills);
  const loading = useSelector((state) => state.bills.loading);
  const alerts = useSelector((state) => state.bills.alerts);
  const selectedYear = useSelector((state) => state.bills.searchOptions.year);
  const yearsOption = useSelector((state) => state.bills.yearsOption);
  const billsStatus = useSelector((state) => state.bills.billsStatus);

  useEffect(() => {
    dispatch(BillsActions.setCNPJ(cnpj));
    dispatch(BillsActions.setSearchYearOption(moment().format('YYYY')));

    BillsService.getBillOptionsSession();

    return () => {
      BillsService.disconnect();
    };
  }, [cnpj, dispatch]);

  useEffect(() => {
    setFilteredBills(bills);
  }, [bills]);

  const clearActiveBill = () => setActiveBill({});

  const handlerYearChange = (value) => {
    clearActiveBill();
    dispatch(BillsActions.setSearchYearOption(value || moment().format('YYYY')));
    dispatch(BillsActions.clearAlerts());
    BillsService.getBillsSession();
  };

  const handlerStatusChange = (value) => {
    clearActiveBill();
    if (value) {
      setFilteredBills(bills.filter((bill) => bill.status === value));
    } else {
      setFilteredBills(bills);
    }
  };

  return (
    <Container>
      <Title>
        <FormattedMessage id="boletos.title" />
      </Title>

      <Subtitle>
        <FormattedMessage id="boletos.subtitle" />
      </Subtitle>

      {!loading && (
        <Form>
          <Select
            id="year"
            label="Ano do Boleto"
            onChange={handlerYearChange}
          >
            {yearsOption.map((year) => (
              <option
                disabled={!year.optante}
                key={year.ano}
                value={year.ano}
                selected={year.ano === selectedYear}
              >
                {year.ano}
              </option>
            ))}
          </Select>
          <Select
            id="year"
            label="Status do Boleto"
            onChange={handlerStatusChange}
            defaultOption
          >
            {billsStatus.map((status) => (
              <option key={status} value={status}>{status}</option>
            ))}
          </Select>
        </Form>
      )}

      <Alerts alerts={alerts} />

      {!loading ? (
        <BoletosWrapper>
          {filteredBills.length ? filteredBills.map((boleto) => (
            <CardBoleto
              key={`${boleto.period}-${Math.random()}`}
              boleto={boleto}
              onClick={setActiveBill}
            />
          )) : (
            <Empty>Nenhum item encontrado, tente refinar a pesquisa acima.</Empty>
          )}
        </BoletosWrapper>
      ) : (
        <Loading>
          <FaSpinner />
          <span>Carregando dados</span>
        </Loading>
      )}

      <BoletoDetails data={activeBill} show={!!Object.keys(activeBill).length} clearActiveBill={clearActiveBill} />
    </Container>
  );
}

export default Boletos;
