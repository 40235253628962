const ptnCnpj = /((\d{1,2})(\s+|\.|-|\\)?(\d{3})(\s+|\.|-|\\)?(\d{3})(\s+|\.|-|\\|\/)?(\d{4})(\s+|\.|-|\\|\/)?(\d{2}))/;

const validateCnpj = (normalizedValue) => {
  if (normalizedValue === '00000000000000'
  || normalizedValue === '11111111111111'
  || normalizedValue === '22222222222222'
  || normalizedValue === '33333333333333'
  || normalizedValue === '44444444444444'
  || normalizedValue === '55555555555555'
  || normalizedValue === '66666666666666'
  || normalizedValue === '77777777777777'
  || normalizedValue === '88888888888888'
  || normalizedValue === '99999999999999') { return false; }

  let tamanho = normalizedValue.length - 2;
  let numeros = normalizedValue.substring(0, tamanho);
  const digitos = normalizedValue.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i -= 1) {
    soma += numeros.charAt(tamanho - i) * pos;
    pos -= 1;
    if (pos < 2) {
      pos = 9;
    }
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado.toString() !== digitos.charAt(0)) {
    return false;
  }

  tamanho += 1;
  numeros = normalizedValue.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i -= 1) {
    soma += numeros.charAt(tamanho - i) * pos;
    pos -= 1;
    if (pos < 2) {
      pos = 9;
    }
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado.toString() !== digitos.charAt(1)) {
    return false;
  }

  return true;
};

export const isValidCnpj = (val) => {
  const reCnpj = new RegExp(ptnCnpj, 'g');
  const matchCnpj = val.match(reCnpj);

  if (matchCnpj) {
    const normalizedValue = val.padStart(14, '0');
    if (validateCnpj(normalizedValue)) {
      return true;
    }
  }

  return false;
};

export default {
  isValidCnpj,
};
