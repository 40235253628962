import styled from 'styled-components';

const wrapper = {
  display: 'flex',
  textAlign: 'left',
  alignItems: 'center',
};

export const Container = styled.footer`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  right: 0;
  padding-left: inherit;
  padding-right: inherit;

  svg {
    height: 60px;
  }

  @media screen and (max-width: 650px) {
    justify-content: center;
  }

  @media screen and (min-height: 700px) {
    bottom: 0;
  }
`;

export const SecurityWrapper = styled.div`
  ${wrapper}

  @media screen and (max-width: 650px) {
    display: none;
    visibility: hidden;
  }
  `;

export const RightsWrapper = styled.div`
  ${wrapper}

  @media screen and (max-width: 650px) {
    text-align: right;
  }
`;

export const RightsButton = styled.div`
  background: ${(props) => (props.theme.origin === 'bradesco' ? '#dc3545' : '#45B347')};
  width: 40px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  color: #fff;

  svg {
    height: 40px;
  }

  @media screen and (max-width: 450px) {
    width: 30px;
    height: 30px;

    svg {
      height: 30px;
    }
  }
`;

export const Text = styled.div`
  margin: 0 20px;
  font-weight: 700;

  @media screen and (max-width: 450px) {
    font-size: 12px;
  }
`;
