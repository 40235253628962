import React from 'react';
import PropTypes from 'prop-types';

import Container from './styles';

function Select({
  id, label, onChange, defaultOption, children,
}) {
  const handlerOnChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <Container>
      {/* eslint-disable-next-line */}
      <label htmlFor={id}>{label}</label>
      <select id={id} onChange={handlerOnChange}>
        {defaultOption && <option key="all" value="">Todos</option>}
        {children}
      </select>
    </Container>
  );
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

Select.defaultProps = {
  defaultOption: false,
};

export default Select;
