import React from 'react';
import PropTypes from 'prop-types';

import { AlertsWrapper } from './styles';

import Alert from '../../components/Alert';

function Alerts({ alerts }) {
  return (
    <AlertsWrapper>
      {alerts.info.map((infoAlert) => (
        <Alert key={Date.now()}>
          {/* eslint-disable-next-line */}
          <p dangerouslySetInnerHTML={{ __html: infoAlert }} />
        </Alert>
      ))}

      {alerts.success.map((successAlert) => (
        <Alert key={Date.now()} alertStyle="success">
          {/* eslint-disable-next-line */}
          <p dangerouslySetInnerHTML={{ __html: successAlert }} />
        </Alert>
      ))}

      {alerts.warning.map((warningAlert) => (
        <Alert key={Date.now()} alertStyle="warning">
          {/* eslint-disable-next-line */}
          <p dangerouslySetInnerHTML={{ __html: warningAlert }} />
        </Alert>
      ))}

      {alerts.error.map((errorAlert) => (
        <Alert key={Date.now()} alertStyle="error">
          {/* eslint-disable-next-line */}
          <p dangerouslySetInnerHTML={{ __html: errorAlert }} />
        </Alert>
      ))}
    </AlertsWrapper>
  );
}

Alerts.propTypes = {
  alerts: PropTypes.shape({
    info: PropTypes.arrayOf(PropTypes.string),
    success: PropTypes.arrayOf(PropTypes.string),
    warning: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default Alerts;
